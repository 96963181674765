

.form--intro-form {
    width         : 100%;
    max-width     : 500px;
    margin-top    : 0 !important;
    margin-bottom : 0 !important;


    @media (min-width : 1200px) {

    }

    .fields {
        margin-bottom : 0 !important;
    }

    .uk-button {
        display         : flex;
        margin          : 27px auto 0 auto; // margins collapse with form input wrapper
        align-items     : center;
        justify-content : center;

        @media (min-width : 1200px) {
            margin-top : 35px; // margins collapse with form input wrapper

        }
    }
}

.link-explain {
    font-size: 19px;
    line-height: 1.1;

    p {
        margin-top: 0;
        margin-bottom: calc(var(--global-margin) * 0.5);

        strong {
            font-size: 21px;
        }
    }
    @media screen and (max-width: 767px) {
        font-size: 17px;

        strong {
            font-size: 19px;
        }
    }

}

.counter-container {
    position: relative;
    height: 100px;
    width: 100px;
}

.timer-container {
    position: absolute;
    height: 100px;
    width: 100px;
    flex: 100px;
    margin-right: -50px;
    margin-inline-start: -50px;
    right: 50%;
    inset-inline-start: 50%;
    transform: scaleX(-1);
}

.timer-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 94px;
    width: 94px;
    flex: 94px;
    top: 3px;
    z-index: 2;
    border-radius: 50%;
    background: var(--global-background);
    margin-right: -47px;
    margin-inline-start: -47px;
    right: 50%;
    inset-inline-start: 50%;

    font-size: 16px;
    letter-spacing: 1.3px;

    @media screen and (min-width: 767px) {
        font-size: 18px;
    }

}

.timer {
    position: relative;
    background: -webkit-linear-gradient(left, var(--global-primary-background) 50%, var(--global-muted-background) 50%);
    border-radius: 100%;
    height: calc(var(--size) * 1px);
    width: calc(var(--size) * 1px);
    -webkit-animation: time calc(var(--duration) * 1s) steps(1000, start) reverse forwards;
    -webkit-mask: radial-gradient(transparent 0%,#000 0%);
    mask: radial-gradient(transparent 0%,#000 0%);
}
.mask {
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    -webkit-animation: mask calc(var(--duration) * 1s) steps(500, start)  reverse forwards;
    -webkit-transform-origin: 100% 50%;
}
@-webkit-keyframes time {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes mask {
    0% {
        background: var(--global-muted-background);
        -webkit-transform: rotate(0deg);
    }
    50% {
        background: var(--global-muted-background);
        -webkit-transform: rotate(-180deg);
    }
    50.01% {
        background: var(--global-primary-background);
        -webkit-transform: rotate(0deg);
    }
    100% {
        background: var(--global-primary-background);
        -webkit-transform: rotate(-180deg);
    }
}


.login-page {
    .info-block-mobile {
        &::before {
            content : ' ';
            position: absolute;
            z-index: 0;
            background: white;
            left: 0;
            top: -30px;
            right: 0;
            width: 100%;
            height: 30px;
        }
    }
}
